*, *:before, *:after
    -moz-box-sizing border-box
    -webkit-box-sizing border-box
    box-sizing border-box

body.has-push-menu,
body.has-push-menu aside,
body.has-push-menu .progress-bar
    -webkit-transition all 0.3s ease
    -moz-transition all 0.3s ease
    transition all 0.3s ease

body.has-push-menu
    overflow-x hidden
    position relative
    right 0
    &.push-menu-to-right
        right rem(0px)
        .progress-bar
            right rem(0px)
        .bar-header
            right rem(0px)

aside.sidebar
    position fixed
    width rem(240px)
    height 100%
    top 0
    right rem(-240px)
    background-color accentDark
    z-index 20
    +above(cut)
        padding rem(10px) 0 0
    &.open
        right 0
    h2
        margin 0 rem(20px) 0
        mainFont(400)
        font-size rem(18px)
        color primaryDark
        border-bottom 1px solid primaryDark
        line-height 50px
        +above(cut)
            font-size rem(20px)
    nav
        ul
            padding 0
            margin rem(5px) 0
            +above(cut)
                margin rem(10px) 0
        li
            margin 0
            list-style-type none
            a
                width 100%
                display block
                padding rem(15px) rem(20px)
                text-decoration none
                mainFont(300)
                color primaryDark
                &:hover
                    color accentDark
                    background themeColor
