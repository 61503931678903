html,
body
    height 100%

.bar-header
    background-color #141414
    padding rem(10px) rem(15px)
    position fixed
    left 0
    width 100%
    z-index 10
    transition top 0.5s, left 0.3s ease
    +above(cut)
        padding rem(15px) rem(0px)
    .logo
        float left
        display inline-block
        margin rem(0px) rem(20px) rem(5px) 0
        height rem(32px)
        line-height rem(32px)
        +above(cut)
            margin rem(0px) rem(20px) rem(5px) rem(25px)
    .logo a
        float left
        display block
        color accentDark
        margin rem(0px) rem(20px) rem(5px) 0
        text-decoration none
        font-size rem(32px)
        position relative
        +above(cut)
            margin rem(0px) rem(20px) rem(5px) rem(25px)
    .nav-link
        float left
        display block
        color #f2f2f2
        text-align center
        padding rem(9px) rem(9px)
        margin-left rem(25px)
        text-decoration none
        font-size 17px
    .nav-dropdown
        float left
        overflow hidden
        padding rem(9px) rem(9px)
        margin-left rem(25px)
        .nav-dropbtn
            font-size 17px
            border none
            outline none
            color white
            background-color inherit
            font-family inherit
            padding 0
            margin 0
        &:hover
            .nav-dropdown-content
                display block
    .nav-dropdown-content
        display none
        position absolute
        margin-top rem(9px)
        background-color #f9f9f9
        min-width 160px
        box-shadow 0px 8px 16px 0px rgba(0, 0, 0, 0.2)
        z-index 1
        .nav-dropdown-link
            float none
            color black
            padding 12px 16px
            text-decoration none
            display block
            text-align left
            &:hover
                background-color #ddd
                color black
    .version
        color accentDark
        font-size rem(10px)
        font-weight normal
        line-height 1
        position absolute
        top 0
        right 0
        display block
        transform translateX(110%)
        opacity .3
    .icon-menu
        float right
        cursor pointer
        margin rem(5px) 0 rem(5px) rem(20px)
        width rem(20px)
        height rem(20px)
        fill accentDark
        +above(cut)
            margin rem(5px) rem(20px) rem(5px) 0
    .dosearch
        float right
        display inline-block
        width rem(30px)
        height rem(30px)
        cursor pointer
        margin 0 0 0 0
        +above(cut)
            margin 0 rem(20px) 0 0
    .icon-search
        width rem(20px)
        height rem(20px)
        fill accentDark
        margin rem(5px)
    .get-theme
        display none
        font-size rem(13px)
        font-weight bold
        text-decoration none
        background-color themeColor
        color accentDark
        padding rem(5px) rem(10px)
        border-radius rem(5px)
        float right
        margin rem(5px) rem(15px) rem(5px) rem(5px)
        +above(cut)
            display inline-block

body.main-page
    background-color #141414
    .bar-header
        background-color rgba(0,0,0,.85)

.overlay
    position fixed
    width 100%
    height 100%
    top 0
    right 0
    left 0
    bottom 0
    pointer-events none
    background rgba(0, 0, 0, 0.6)
    z-index 17
    opacity 0
    transition all 0.3s
    &.show
        pointer-events auto
        opacity 1
